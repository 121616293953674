<template>
    <app-wrapper>
        <template v-slot:child>
            <ChartOfAccountHeader />

            <slot name="child"> </slot>
        </template>
    </app-wrapper>
</template>

<script>
import AppWrapper from '@/layout/AppWrapper'
import ChartOfAccountHeader from './ChartOfAccountHeader.vue'

export default {
    name: 'SettingsWrapper',
    components: { AppWrapper, ChartOfAccountHeader },
}
</script>

<style scoped></style>