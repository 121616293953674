<template>
    <chart-of-account-wrapper>
        <template v-slot:child>
            <manage-account-header />
            <div class="w-90 center mt4">
                <div class="flex justify-between items-center manageHeader secPad">
                    <div class="b w-50">Account</div>
                    <div class="b w-50">Description</div>
                    <div class="b">
                        <!-- Action -->
                    </div>
                </div>
                <section class="secPad">
                    <div class="flex justify-between items-center secFirst">
                        <div class="pl3">Sales</div>
                        <div></div>
                        <div>
                            <!-- <button class="flex items-center" style="gap: 10px">
                                <span><img :src="require('@/assets/images/chartCircle.svg')" /></span><span>Add account</span>
                            </button> -->
                        </div>
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                           <router-link :to="{ name: 'IncomeSales' }">Sales</router-link>
                        </div>
                        <div class="w-50 tl">Sale of products and services</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'IncomeOtherSales' }">Other sales</router-link>
                        </div>
                        <div class="w-50 tl">Other sales of products and services</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                </section>
                <section class="secPad">
                    <div class="flex justify-between items-center secFirst">
                        <div class="pl3">Other Income</div>
                        <div></div>
                        <div>
                            <!-- <button class="flex items-center" style="gap: 10px">
                                <span><img :src="require('@/assets/images/chartCircle.svg')" /></span><span>Add account</span>
                            </button> -->
                        </div>
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'IncomeDiscountReceived' }">Discount Received</router-link>
                        </div>
                        <div class="w-50 tl">Discount received on products and services purchased</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'IncomeBadDebtRecovered' }">Bad Debts Recovered</router-link>
                        </div>
                        <div class="w-50 tl">Bad debt recovered from sales made to customers</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'IncomeInterestReceived' }">Interest Received</router-link>
                        </div>
                        <div class="w-50 tl">Interest recived on loans </div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'IncomeShipping' }">Shipping</router-link>
                        </div>
                        <div class="w-50 tl">Delivery and shipping fees from Sales</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'IncomeServiceCharge' }">Service Charge</router-link>
                        </div>
                        <div class="w-50 tl">Income earned from service charge</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'IncomeOtherIncome' }">Other Income</router-link>
                        </div>
                        <div class="w-50 tl">Value of other income earned by the busines</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'IncomeOtherSalesIncome' }">Other Sales Income</router-link>
                        </div>
                        <div class="w-50 tl">Other income from Sales </div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'IncomeGiftReceived' }">Gift Received</router-link>
                        </div>
                        <div class="w-50 tl">Value of gift and grants earned as Income</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                    <div class="flex justify-between items-center secPad">
                        <div class="w-50 b">
                            <router-link :to="{ name: 'IncomeCommissionEarned' }">Commission on Sales</router-link>
                        </div>
                        <div class="w-50 tl">Commisions earned from Sales</div>
                        <!-- <div class="w-50 tr">
                            <img :src="require('@/assets/images/chartPen.svg')" />
                        </div> -->
                    </div>
                </section>
            </div>
        </template>
    </chart-of-account-wrapper>
</template>
<script>
import ChartOfAccountWrapper from '../widgets/ChartOfAccountWrapper.vue'
import ManageAccountHeader from '../widgets/ManageAccountHeader.vue'
export default {
    name: 'ManageIncome',
    components: { ManageAccountHeader, ChartOfAccountWrapper },
    setup() {
        return {}
    },
}
</script>
<style scoped>
.secPad div {
    align-items: flex-start !important;
}
</style>
