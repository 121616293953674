<template>
    <div class="w-90 center mt3">
        <div class="flex scrollmenu box-border-bottom">
            <router-link active-class="active-menu" :to="{ name: 'ManageAssets' }">
                <div class="mr3">Assets</div>
            </router-link>
            <router-link active-class="active-menu" :to="{ name: 'ManageLiability' }">
                <div class="mr3">Liabilities</div>
            </router-link>
            <router-link active-class="active-menu" :to="{ name: 'ManageIncome' }">
                <div class="mr3">Income</div>
            </router-link>
            <router-link active-class="active-menu" :to="{ name: 'ManageExpenses' }">
                <div class="mr3">Expenses</div>
            </router-link>
            <router-link active-class="active-menu" :to="{ name: 'ManageEquity' }">
                <div class="mr3">Equity</div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'manageAccountHeader',

    setup() {
       
        return {
        
        }
    }
}
</script>

<style scoped></style>